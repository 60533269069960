@use '@angular/material' as mat;
@import 'material';

* {
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        'Open Sans',
        'Helvetica Neue',
        sans-serif;
    margin: 0px;
}

.text-center {
    text-align: center;
}

.center {
    margin: auto;
}

.flex {
    display: flex;
    justify-content: center;
}

.flex-column {
    flex-direction: column;
}

// main carousel
.p-carousel-prev,
.p-carousel-next {
    display: none !important;
}

.ui-carousel-dots-container {
    display: none !important;
}

.p-carousel-indicators {
    display: none !important;
}

.p-carousel {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.p-carousel-content {
    overflow: hidden;
}

a {
    text-decoration: none;
}

body {
    background-color: #ECEFF1;
}

.mat-elevation-z1 {
    box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f
}

html,
body {
    height: 100%;
}

.green-icon {
    color: green;
}

.red-icon {
    color: red;
}

.p-10 {
    padding: 10px;
}

.p-b-10 {
    padding-bottom: 10px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-l-10 {
    margin-left: 10px;
}

.w-100-proc {
    width: 100%;
}

.attachment-icon {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    display: inline-block;
}

.menu+* .mat-menu-panel {
    background-color: #003865 !important;

    * {
        color: #fff !important;
    }
}

.menu+* .mat-menu-panel .mat-menu-item:hover {
    background-color: rgba(192, 192, 192, 0.75) !important;
    color: #333 !important;
}

.p-button {
    background-color: #003865 !important;
    border: #003865 !important;
}

.h-10 {
    height: 10px;
}

@media(max-height: 1024px) {
    p-table {
        * {
            font-size: 12px;
        }

    }

    .p-calendar .p-inputtext {
        padding: 5px;
    }

    .p-datatable .p-datatable-tbody>tr>td {
        padding: 1rem 1rem !important;
    }

}

.p-sidebar {
    opacity: 4 !important;
    background: linear-gradient(rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.7)),
        url(assets/images/IMG_8391.jpg) !important;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    overflow: hidden !important;
    height: 100vh;
}

.p-editor-container {
    width: 350px;
}

@media(max-width: 500px) {
    .p-editor-container {
        width: 300px;
    }
}

@media(max-width: 350px) {
    .p-editor-container {
        width: 250px;
    }
}


@include mat.core();

.dense-3 {
    @include mat.all-component-densities(-5);
}

.spin {
    animation: spin 1s reverse;
}


@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}



html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;

    --accent-color: #00D;
    --snack-info-color: #0f0;
    --snack-warn-color: #ff0;
    --snack-error-color: #f00;
    --app-background-color: #f4f3f6;
}