.snack-error>div {
    border-left-style: solid;
    border-left-color: #f00;
    border-left-width: 15px;

    button {
        border: #aaa solid 1px;
    }
}

.snack-warn>div {
    border-left-style: solid;
    border-left-color: #ffd740;
    border-left-width: 15px;

    button {
        border: #aaa solid 1px;
    }
}

.snack-success>div {
    border-left-style: solid;
    border-left-color: #080;
    border-left-width: 15px;

    button {
        border: #aaa solid 1px;
    }
}

.snack>div {
    border-left-width: 15px;

    button {
        border: #aaa solid 1px;
    }
}

.snack-info>div {
    white-space: pre-wrap;
    border-left-style: solid;
    border-left-color: #3B5876;
    border-left-width: 15px;

    button {
        border: #aaa solid 1px;
    }
}

/* set tab body height to 100%  to allow swpie acion on mobile */
.mat-mdc-tab-body-wrapper {
    height: 100%;
}